import styles from './styles.module.css';
import React, { CSSProperties } from 'react';

interface CommonSelectlistProps {
	name: string;
	placeHolder: string;
	values: {
		value: string;
		label: string;
	}[];
	outerContainerStyle?: CSSProperties;
}

interface CommonControlledSelectlistProps {
	selectedOptionValue?: string;
	onChange: (event: React.ChangeEvent<HTMLSelectElement>) => any;
}

interface Props extends CommonSelectlistProps {
	containerStyle?: CSSProperties;
	errorMessageStyle?: CSSProperties;
	ref?: any;
	errorMessage?: string;
}

export const notSelected = 'notSelected';

interface PropsCsl extends CommonSelectlistProps, CommonControlledSelectlistProps {
	containerStyle?: CSSProperties;
}

export const CustomControlledSelectlist = (props: PropsCsl) => {
	const {
		values,
		placeHolder,
		outerContainerStyle,
		containerStyle,
		selectedOptionValue = notSelected,
		onChange,
		name,
	} = props;
	return (
		<div className={styles.outerContainer} style={outerContainerStyle}>
			<div className={styles.defaultContainer} style={containerStyle}>
				<div className={styles.selectDiv}>
					<select
						name={name}
						value={selectedOptionValue}
						className={styles.selectClass}
						onChange={onChange}
					>
						<option key={notSelected} value={notSelected}>
							{placeHolder}
						</option>
						{values.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</select>
				</div>
			</div>
		</div>
	);
};

export const CustomSelectlist = React.forwardRef((props: Props, ref: any) => {
	const {
		placeHolder,
		containerStyle,
		name,
		errorMessage,
		errorMessageStyle,
		outerContainerStyle,
		values,
	} = props;

	return (
		<div className={styles.outerContainer} style={outerContainerStyle}>
			<div className={styles.defaultContainer} style={containerStyle}>
				<div className={styles.selectDiv}>
					<select name={name} ref={ref} className={styles.selectClass}>
						<option key={notSelected} value={notSelected}>
							{placeHolder}
						</option>
						{values.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</select>
				</div>
			</div>
			<div className={styles.errorMessage} style={errorMessageStyle}>
				{errorMessage}
			</div>
		</div>
	);
});

interface PropsSbsl extends CommonSelectlistProps, CommonControlledSelectlistProps {}

export const CustomSortBySelectlist = (props: PropsSbsl) => {
	const { name, values, outerContainerStyle, selectedOptionValue, onChange, placeHolder } = props;

	return (
		<div className={styles.sortByContainer} style={outerContainerStyle}>
			<span className={styles.sortByLabel}>{placeHolder}</span>
			<div className={styles.sortBySelectlistWrapper}>
				<select
					name={name}
					value={selectedOptionValue}
					className={styles.sortBySelectlist}
					onChange={onChange}
				>
					{values.map((option) => (
						<option key={option.value} value={option.value}>
							{option.label}
						</option>
					))}
				</select>
			</div>
		</div>
	);
};
