import styles from './styles.module.css';
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import classNames from 'classnames';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React, { useState, useRef, ComponentType } from 'react';
import { ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import { CustomButton, CustomButtonProps } from 'src/components/formComponents/customButton';
import { CustomCheckbox } from 'src/components/formComponents/customCheckbox';
import { CustomInput } from 'src/components/formComponents/customInput';
import { CustomRadioGroup } from 'src/components/formComponents/customRadioGroup';
import { CustomSelectlist, notSelected } from 'src/components/formComponents/customSelectlist';
import { ReadMore } from 'src/components/readMore';
import * as Icons from 'src/icons';
import { CaretIcon } from 'src/icons';
import { ApolloManager } from 'src/utils/apollo-manager';
import {
	BackEndContentTypes,
	ContentfulTypes,
	isMainPage,
	getPageName,
	formatDate,
} from 'src/utils/common';
import formSubmission from 'src/utils/dynamicForm.graphql';
import { LinkHelper } from 'src/utils/link-helper';
import { AnchorIcon } from 'src/icons/anchor';
import { collapsiblePanelMap } from './collapsiblePanelMap';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

const MAX_TEXT_SIZE = 5_000; // virtually no limit, just a sanity check.

enum SubmitState {
	NotSubmitted,
	Submitting,
	SuccessfullySubmitted,
	UnsuccessfullySubmitted,
}

enum FormItemType {
	shortInput,
	longInput,
	dropdownList,
	formGroupTitle,
	formGroupText,
	checkbox,
	options,
}

interface FormResult {
	result: string;
}

const getContentType = (target: any) => {
	const fromBackend =
		target?.sys?.contentType?.sys?.contentful_id || target?.sys?.contentType?.sys?.id;
	const fromGatsbyPlugin = target?.__typename;
	return fromBackend || fromGatsbyPlugin;
};

const getField = (target: any, fieldName: string) => {
	// shape when asking Contentful directly (backend does this)
	if (target?.fields) return target.fields[fieldName];
	// shape when using gatsby-source-contentful plugin (frontend does this)
	if (target) return target[fieldName];
	return undefined;
};

const getId = (target: any) => {
	const idFromBackend = target?.sys?.contentful_id || target?.sys?.id;
	const idFromGatsbyPlugin = target?.contentful_id;
	return idFromBackend || idFromGatsbyPlugin;
};

// original name should not contain dots - useForm gets confused
// original name should not contain '_|_', we use that for a special purpose.
const curateName = (name: string | undefined) => {
	if (!name) return name;
	// replace '.' and '_|_' for ''
	return name.replace(/\.|(_\|_)/g, '');
};

const DynamicForm = (props: any) => {
	const { node } = props;
	const { register, handleSubmit, errors, reset } = useForm();
	const [submitState, setSubmitState] = useState(SubmitState.NotSubmitted);
	const [submitMessage, setSubmitMessage] = useState<string | undefined>(undefined);

	if (!node) {
		return <div></div>;
	}

	const formInputs = getField(node.data?.target, 'formInputs');
	const title = getField(node.data?.target, 'title');

	const onSubmit = async (data: any) => {
		setSubmitState(SubmitState.Submitting);

		let submissionDetails = [];
		for (let submissionDetail in data) {
			if (submissionDetail !== 'areYouAHuman') {
				const sdValue = data[submissionDetail];
				let item = { fieldName: submissionDetail } as any;
				if (typeof sdValue === 'boolean') {
					item.booleanValue = sdValue;
				} else if (typeof sdValue === 'string') {
					item.stringValue = sdValue;
				} else {
					item.stringValue = JSON.stringify(sdValue);
				}
				submissionDetails.push(item);
			}
		}

		try {
			await ApolloManager.client.mutate<FormResult>({
				mutation: formSubmission,
				variables: {
					formName: title,
					isItARobot: data.areYouAHuman,
					values: submissionDetails,
				},
			});
			setSubmitState(SubmitState.SuccessfullySubmitted);
			setSubmitMessage('Thank you, your details have been submitted.');
		} catch (error) {
			console.error('Error Submitting Embedded Form: ', error, data);
			setSubmitState(SubmitState.UnsuccessfullySubmitted);
			setSubmitMessage(
				'There was an error processing your submission. Please try again later or contact us by phone. We apologise for the inconvenience'
			);
		}
	};

	const onReset = () => {
		setSubmitState(SubmitState.NotSubmitted);
		setSubmitMessage(undefined);
		reset();
	};

	if (formInputs) {
		const getFormItemType = (item: any): FormItemType | undefined => {
			if (getField(item, 'shortInputText')) return FormItemType.shortInput;
			if (getField(item, 'longInputText')) return FormItemType.longInput;
			if (getField(item, 'dropdownList')) return FormItemType.dropdownList;
			if (getField(item, 'formGroupTitle')) return FormItemType.formGroupTitle;
			if (getField(item, 'formGroupText')) return FormItemType.formGroupText;
			if (getField(item, 'checkbox')) return FormItemType.checkbox;
			if (getField(item, 'options')) return FormItemType.options;
			return undefined;
		};

		const getFormItemJsx = (item: any) => {
			const itemType = getFormItemType(item);
			const requiredRule = {
				required: {
					value: true,
					message: 'This field is required',
				},
			};
			const textSizeRule = {
				validate: (value: string) => {
					if (value.length >= MAX_TEXT_SIZE) {
						return `input is too long`;
					}
				},
			};
			const selectListRule = {
				validate: (value: string) => {
					return value === notSelected ? 'Please select an option' : undefined;
				},
			};

			let name = '';

			switch (itemType) {
				case FormItemType.shortInput:
					name = `${curateName(getField(item, 'shortInputText'))}_|_${getId(item)}`;
					return (
						<CustomInput
							name={name}
							placeHolder={getField(item, 'shortInputText')}
							type="text"
							outerContainerStyle={{ margin: '0' }}
							ref={
								getField(item, 'shortInputIsRequired')
									? register({ ...requiredRule, ...textSizeRule })
									: register(textSizeRule)
							}
							required={getField(item, 'shortInputIsRequired') || false}
							errorMessage={errors ? errors[name]?.message : undefined}
						/>
					);
				case FormItemType.longInput:
					name = `${curateName(getField(item, 'longInputText'))}_|_${getId(item)}`;
					return (
						<div className={styles.textAreaContainer}>
							<CustomInput
								name={name}
								placeHolder={getField(item, 'longInputText')}
								type="textArea"
								outerContainerStyle={{ margin: '0', position: 'absolute' }}
								ref={
									getField(item, 'longInputIsRequired')
										? register({ ...requiredRule, ...textSizeRule })
										: register(textSizeRule)
								}
								required={getField(item, 'longInputIsRequired') || false}
								errorMessage={errors ? (errors[name] as any)?.message : undefined}
							/>
						</div>
					);
				case FormItemType.formGroupTitle:
					return <h6 className={styles.divisionTitle}>{getField(item, 'formGroupTitle')}</h6>;
				case FormItemType.formGroupText:
					// backend (which queries Contentful directly) scenario
					const paragraphs = getField(item, 'formGroupText')?.content;
					if (paragraphs) {
						return (
							<div>
								{paragraphs
									?.filter((p: any) => p)
									.map(
										(p: any, i: number) =>
											p.content?.length &&
											p.content.length > 0 && (
												<p key={i} className={styles.nodeParagraph}>
													{p.content[0].value}
												</p>
											)
									)}
							</div>
						);
					}
					// frontend (which uses gatsby plugin) scenario
					return documentToReactComponents(
						JSON.parse(getField(item, 'formGroupText')?.raw || null),
						defaultRichTextOptions
					);

				case FormItemType.dropdownList:
					name = `${curateName(getField(item, 'dropdownLabel')) || 'Select'}_|_${getId(item)}`;
					return (
						<CustomSelectlist
							name={name}
							placeHolder={getField(item, 'dropdownLabel') || 'Select'}
							outerContainerStyle={{ margin: '0' }}
							ref={getField(item, 'dropdownListIsRequired') ? register(selectListRule) : register}
							errorMessage={errors ? (errors[name] as any)?.message : undefined}
							values={getField(item, 'dropdownList')
								?.filter((v: string | null | undefined) => v)
								.map((v: string) => ({
									value: v,
									label: v,
								}))}
						/>
					);
				case FormItemType.options:
					name = `Option_|_${getId(item)}`;
					return (
						<CustomRadioGroup
							name={name}
							ref={register}
							className={styles.optionsContainer}
							labelContainerStyle={{ marginRight: '20px' }}
							options={getField(item, 'options')
								?.filter((o: string | null | undefined) => o)
								.map((o: string, i: number) => ({
									value: o,
									label: o,
									selected: i === 0,
								}))}
						/>
					);
				case FormItemType.checkbox:
					name = `${curateName(getField(item, 'checkbox'))}_|_${getId(item)}`;
					return (
						<CustomCheckbox
							name={name}
							labelJsx={getField(item, 'checkbox')}
							labelClassName={styles.checkboxLabel}
							containerStyle={{ margin: '20px 0' }}
							ref={getField(item, 'checkboxIsRequired') ? register(requiredRule) : register}
							required={getField(item, 'checkboxIsRequired') || false}
							errorMessage={errors ? (errors[name] as any)?.message : undefined}
						/>
					);
				default:
					return undefined;
			}
		};

		// organises form items in rows.
		// short inputs and dropdownlist can go alongside
		// other form items need their own row (text-areas, checkboxes, etc.)
		let rows = [];
		let i = 0;
		while (i < (formInputs?.length || -1)) {
			const iItem = formInputs[i];
			if (iItem) {
				let row = [iItem];
				const iType = getFormItemType(iItem);
				if (iType === FormItemType.shortInput || iType === FormItemType.dropdownList) {
					const j = i + 1;
					if (j < formInputs.length) {
						const jItem = formInputs[j];
						const jType = getFormItemType(jItem);
						if (jType === FormItemType.shortInput || jType === FormItemType.dropdownList) {
							row.push(jItem);
							i = j;
						}
					}
				}
				rows.push(row);
			}
			i++;
		}

		const getButtonJsx = () => {
			let buttonProps: CustomButtonProps = {
				type: 'submit',
				disabled: false,
				content: 'Submit',
				iconRight: <CaretIcon paddingPx={{ left: 80 }} />,
				variant: 'primary',
				inlineStyle: { margin: '0' },
			};
			switch (submitState) {
				case SubmitState.Submitting:
					buttonProps.content = 'Submitting';
					buttonProps.disabled = true;
					break;
				case SubmitState.SuccessfullySubmitted:
					buttonProps.content = 'Submitted';
					buttonProps.disabled = true;
					buttonProps.iconRight = undefined;
					break;
				case SubmitState.UnsuccessfullySubmitted:
					buttonProps.content = 'Reset';
					buttonProps.type = 'reset';
					buttonProps.variant = 'secondary';
					break;
			}

			return <CustomButton {...buttonProps} />;
		};

		return (
			<div>
				{title && <h5 className={styles.h5}>{title}</h5>}
				<form className={styles.gridForm} onSubmit={handleSubmit(onSubmit)} onReset={onReset}>
					<div className={styles.areYouAHuman}>
						<CustomCheckbox
							name="areYouAHuman"
							labelJsx={'Please leave this un--ticked'}
							ref={register}
							tabIndex={-1}
							autoComplete="off"
						/>
					</div>
					{rows.map((row: any, i: number) => {
						if (row.length === 1) {
							return <div key={`${i}_${getId(row[0])}`}>{getFormItemJsx(row[0])}</div>;
						} else if (row.length === 2) {
							return (
								<div key={`${i}_${getId(row[0])}`} className={styles.grid2cols}>
									<div>{getFormItemJsx(row[0])}</div>
									<div>{getFormItemJsx(row[1])}</div>
								</div>
							);
						}
					})}
					<div className={styles.submit}>{getButtonJsx()}</div>
					{submitMessage && (
						<div className={styles.submitMessageContainer}>
							<p>{submitMessage}</p>
						</div>
					)}
				</form>
			</div>
		);
	}
	return <div></div>;
};

const parseEmbeddedAsset = (node: any) => {
	if (getField(node?.data?.target, 'file')?.contentType?.startsWith('image')) {
		const maxWidth = getField(node.data.target, 'file')?.details?.image?.width;
		return (
			<img
				src={getField(node.data.target, 'file')?.url}
				style={maxWidth && { maxWidth: `${maxWidth}px` }}
			/>
		);
	}
	return undefined;
};

const parseEntryHyperlink = (node: any) => {
	const entryType = getContentType(node?.data?.target);
	if (
		Object.values(ContentfulTypes).includes(entryType) ||
		Object.values(BackEndContentTypes).includes(entryType)
	) {
		let link = '';
		let linkText: string | undefined =
			(node?.content?.length || 0) > 0 ? node.content[0].value : undefined;
		if (isMainPage(entryType as ContentfulTypes | BackEndContentTypes)) {
			link = `/${LinkHelper.getPageBaseUrl(entryType)}`;
			if (!linkText) {
				linkText = getPageName(entryType as ContentfulTypes);
			}
		} else {
			// it may be a resource, check if we have an id
			const resourceId = getId(node?.data?.target);
			if (resourceId) {
				link = LinkHelper.getLinkOfContentType(entryType as ContentfulTypes, resourceId);
				if (!linkText) {
					linkText = getField(getField(node?.data?.target, 'thumbnailLink'), 'title');
				}
			}

			// it may be a generic page or a resource
			// generic pages have a slug
			if (entryType === ContentfulTypes.Page || entryType === ContentfulTypes.ContentfulPage) {
				link = `/${getField(node?.data?.target, 'url')}` || '';
				if (!linkText) {
					linkText = getField(node?.data?.target, 'title');
				}
			} else if (
				entryType === ContentfulTypes.CollapsiblePanel ||
				entryType === ContentfulTypes.ContentfulCollapsiblePanel
			) {
				const id: string = getId(node?.data?.target);
				if (id) {
					link = collapsiblePanelMap[id];
					if (!linkText) {
						linkText = getField(node?.data?.target, 'title');
					}
				}
			}
		}
		if (link && linkText) {
			return (
				<AnchorLink className={styles.nodeEntryHyperlink} to={link}>
					{linkText}
				</AnchorLink>
			);
		}
	}
	return undefined;
};
const parseAssetHyperlink = (node: any) => {
	if (
		getField(node?.data?.target, 'file')?.url &&
		node.content?.length &&
		node.content.length > 0 &&
		node.content[0].value
	) {
		return (
			<a
				className={styles.nodeAssetHyperlink}
				href={getField(node.data.target, 'file')?.url}
				target="_blank"
				rel="noopener noreferrer"
			>
				{node.content[0].value}
			</a>
		);
	}
	return undefined;
};
const parseEmbeddedEntry = (node: any, type: 'block' | 'inline') => {
	const contentType = getContentType(node?.data?.target);
	if (contentType === BackEndContentTypes.DynamicForm || contentType === ContentfulTypes.FormTab) {
		return <DynamicForm node={node} />;
	}

	const target = node?.data?.target;
	const contentful_id = getId(node?.data?.target);
	let readMoreComponent = undefined;
	if (contentType && target && contentful_id) {
		const containerClass = classNames(
			{ [styles.nodeEmbeddedEntryBlock]: type === 'block' },
			{ [styles.nodeEmbeddedEntryInline]: type === 'inline' }
		);
		let isResource = false;
		let Icon: ComponentType<any> | undefined =
			Icons[getField(getField(target, 'thumbnailLink'), 'thumbnailIcon') as keyof typeof Icons] ||
			undefined;
		let typeText = undefined;
		let publicationDate = getField(target, 'publicationDate')
			? formatDate(getField(target, 'publicationDate'))
			: undefined;
		let linkTo: string | undefined = LinkHelper.getLinkOfContentType(contentType, contentful_id);
		let externalLink = undefined;
		let readMoreText = undefined;
		let asVideo = false;
		switch (contentType) {
			case BackEndContentTypes.Project:
			case ContentfulTypes.Project:
				isResource = true;
				typeText = getField(target, 'projectType');
				break;
			case BackEndContentTypes.Article:
			case ContentfulTypes.Article:
				isResource = true;
				typeText = getField(target, 'articleType');
				if (getField(target, 'externalArticleUrl')) {
					linkTo = undefined;
					externalLink = getField(target, 'externalArticleUrl');
				}
				break;
			case BackEndContentTypes.Publication:
			case ContentfulTypes.Publication:
				isResource = true;
				typeText = getField(target, 'publicationType');
				if (getField(target, 'externalPublicationUrl')) {
					linkTo = undefined;
					externalLink = getField(target, 'externalPublicationUrl');
				}
				break;
			case BackEndContentTypes.Report:
			case ContentfulTypes.Report:
				isResource = true;
				typeText = getField(target, 'reportType');
				break;
			case BackEndContentTypes.Video:
			case ContentfulTypes.Video:
				isResource = true;
				typeText = 'Video';
				Icon = undefined;
				publicationDate = undefined;
				externalLink = getField(target, 'externalVideoUrl');
				readMoreText = 'Watch Video';
				asVideo = true;
				break;
		}

		readMoreComponent = (
			<ReadMore
				asCard
				image={{
					src: getField(getField(getField(target, 'thumbnailLink'), 'thumbnailImage'), 'file')?.url,
				}}
				icon={{ Icon }}
				title={{
					titleText: getField(getField(target, 'thumbnailLink'), 'title'),
				}}
				publishedDate={publicationDate}
				linkSection={{
					linkTo,
					externalLink,
					withBorderTop: true,
					typeText,
					readMoreText,
				}}
				asVideoUrl={asVideo ? linkTo || externalLink : undefined}
			>
				{getField(getField(target, 'thumbnailLink'), 'description')}
			</ReadMore>
		);

		return isResource ? <div className={containerClass}>{readMoreComponent}</div> : undefined;
	}
	return undefined;
};

export enum AnchorTitleTypes {
	H1 = 'h1',
	H2 = 'h2',
	H3 = 'h3',
	H4 = 'h4',
	H5 = 'h5',
	H6 = 'h6',
}

interface TitleProps {
	prependId?: string;
	titleType: AnchorTitleTypes;
	children?: any;
	className?: string;
}

export const AnchorTitle = (props: TitleProps) => {
	const { prependId, children, titleType, className } = props;
	const inputRef = useRef<HTMLInputElement>(null);

	const onCopyLink = (ev: React.MouseEvent) => {
		if (inputRef) {
			inputRef.current?.select && inputRef.current.select();
			document?.execCommand && document?.execCommand('copy');
			ev.stopPropagation();
		}
	};

	const getAnchorId = (children: any): string | undefined => {
		if (children && (children?.length || 0) > 0 && typeof children[0] === 'string') {
			return `${prependId ? prependId + '-' : ''}content-${LinkHelper.parseInternalLink(
				children[0] as string
			)}`;
		}
		return undefined;
	};

	const wrap = (children: any, id?: string) => {
		const internalClassName = classNames(styles.anchorTitle, className);
		return React.createElement(titleType, { className: internalClassName, id }, children);
	};

	if (!children) return null;

	const anchorId = getAnchorId(children);

	return wrap(
		<>
			<div className={styles.anchorContainer} onClick={onCopyLink}>
				<AnchorIcon />
				<input
					className={styles.copyInput}
					type="text"
					value={
						anchorId && typeof window !== 'undefined'
							? `${window.location.origin}${window.location.pathname}#${anchorId}`
							: ''
					}
					ref={inputRef}
					readOnly
				/>
			</div>
			{children}
		</>,
		anchorId
	);
};

export const defaultRichTextOptions = {
	renderMark: {
		[MARKS.BOLD]: (text: ReactNode) => <b className={styles.markBold}>{text}</b>,
		[MARKS.ITALIC]: (text: ReactNode) => <i className={styles.markItalic}>{text}</i>,
	},
	renderNode: {
		[BLOCKS.PARAGRAPH]: (node: any, children: ReactNode) => (
			<p className={styles.nodeParagraph}>{children}</p>
		),
		[BLOCKS.HEADING_1]: (node: any, children: ReactNode) => (
			<AnchorTitle children={children} titleType={AnchorTitleTypes.H1} className={styles.nodeH1} />
		),
		[BLOCKS.HEADING_2]: (node: any, children: ReactNode) => (
			<AnchorTitle children={children} titleType={AnchorTitleTypes.H2} className={styles.nodeH2} />
		),
		[BLOCKS.HEADING_3]: (node: any, children: ReactNode) => (
			<AnchorTitle children={children} titleType={AnchorTitleTypes.H3} className={styles.nodeH3} />
		),
		[BLOCKS.HEADING_4]: (node: any, children: ReactNode) => (
			<AnchorTitle children={children} titleType={AnchorTitleTypes.H4} className={styles.nodeH4} />
		),
		[BLOCKS.HEADING_5]: (node: any, children: ReactNode) => (
			<AnchorTitle children={children} titleType={AnchorTitleTypes.H5} className={styles.nodeH5} />
		),
		[BLOCKS.HEADING_6]: (node: any, children: ReactNode) => (
			<AnchorTitle children={children} titleType={AnchorTitleTypes.H6} className={styles.nodeH6} />
		),
		[BLOCKS.EMBEDDED_ENTRY]: (node: any, children: ReactNode) => parseEmbeddedEntry(node, 'block'),
		[BLOCKS.EMBEDDED_ASSET]: (node: any, children: ReactNode) => (
			<div className={styles.nodeEmbeddedAsset}>{parseEmbeddedAsset(node)}</div>
		),
		[BLOCKS.UL_LIST]: (node: any, children: ReactNode) => (
			<ul className={styles.nodeUl}>{children}</ul>
		),
		[BLOCKS.OL_LIST]: (node: any, children: ReactNode) => (
			<ol className={styles.nodeOl}>{children}</ol>
		),
		[BLOCKS.LIST_ITEM]: (node: any, children: ReactNode) => (
			<li className={styles.nodeListItem}>{children}</li>
		),
		[BLOCKS.QUOTE]: (node: any, children: ReactNode) => (
			<blockquote className={styles.nodeQuote}>{children}</blockquote>
		),
		[BLOCKS.HR]: () => <hr className={styles.nodeHr} />,
		[INLINES.ASSET_HYPERLINK]: (node: any) => parseAssetHyperlink(node),
		[INLINES.ENTRY_HYPERLINK]: (node: any, children: ReactNode) => parseEntryHyperlink(node),
		[INLINES.EMBEDDED_ENTRY]: (node: any) => parseEmbeddedEntry(node, 'inline'),
		[INLINES.HYPERLINK]: (node: any, children: ReactNode) => (
			<a className={styles.nodeHyperlink} href={node.data.uri}>
				{children}
			</a>
		),
	},
};

export const getDefaultRichTextOptionsPrependId = (prependId: string) => {
	return {
		...defaultRichTextOptions,
		renderNode: {
			...defaultRichTextOptions.renderNode,
			[BLOCKS.HEADING_1]: (node: any, children: ReactNode) => (
				<AnchorTitle
					prependId={prependId}
					children={children}
					titleType={AnchorTitleTypes.H1}
					className={styles.nodeH1}
				/>
			),
			[BLOCKS.HEADING_2]: (node: any, children: ReactNode) => (
				<AnchorTitle
					prependId={prependId}
					children={children}
					titleType={AnchorTitleTypes.H2}
					className={styles.nodeH2}
				/>
			),
			[BLOCKS.HEADING_3]: (node: any, children: ReactNode) => (
				<AnchorTitle
					prependId={prependId}
					children={children}
					titleType={AnchorTitleTypes.H3}
					className={styles.nodeH3}
				/>
			),
			[BLOCKS.HEADING_4]: (node: any, children: ReactNode) => (
				<AnchorTitle
					prependId={prependId}
					children={children}
					titleType={AnchorTitleTypes.H4}
					className={styles.nodeH4}
				/>
			),
			[BLOCKS.HEADING_5]: (node: any, children: ReactNode) => (
				<AnchorTitle
					prependId={prependId}
					children={children}
					titleType={AnchorTitleTypes.H5}
					className={styles.nodeH5}
				/>
			),
			[BLOCKS.HEADING_6]: (node: any, children: ReactNode) => (
				<AnchorTitle
					prependId={prependId}
					children={children}
					titleType={AnchorTitleTypes.H6}
					className={styles.nodeH6}
				/>
			),
		},
	};
};

export const getRichText = (content: any, richTextOptions: Options) => {
	if (content?.raw) {
		return renderRichText(content, richTextOptions);
	}
	if (content?.json) {
		return documentToReactComponents(content?.json, richTextOptions);
	}
	return null;
};
